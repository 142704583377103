// Core
import { useTranslation } from "next-i18next";

// Components
import { Space } from "components/ui/Space";
import { Icon } from "components/ui/Icon";
import { Button } from "components/ui/Button";
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./Styles.module.less";

export type ConfirmChangeCountryProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
  country?: string;
  testId?: string;
};

export const ConfirmChangeCountry = (props: ConfirmChangeCountryProps) => {
  const { onConfirm, onCancel, country, testId = "confirm-change-country" } = props;
  const { t } = useTranslation();
  return (
    <div className={st["confirm-change-country"]} data-test-id={testId}>
      <div className={st["confirm-change-country-content"]}>
        <Icon name="Info" className={st.icon} />
        <Text size="14" color="gray-700">
          {t("intro:confirmCountry.text", { country })}
        </Text>
      </div>
      <div className={st["confirm-change-country-actions"]}>
        <Space>
          <Button
            className={st["fit-button"]}
            type="primary"
            ghost
            onClick={onCancel}
            testId={`${testId}-btn-confirm`}
          >
            {t("intro:confirmCountry.cancel")}
          </Button>
          <Button
            className={st["fit-button"]}
            type="primary"
            onClick={onConfirm}
            testId={`${testId}-btn-cancel`}
          >
            {t("intro:confirmCountry.confirm")}
          </Button>
        </Space>
      </div>
    </div>
  );
};
