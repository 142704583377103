// Core
import { AxiosRequestConfig } from "axios";
import QS from "qs";

// Definitions
import { HttpResponse } from "models/Http";
import {
  LeadHash,
  LeadParamsType,
  LeadProfileType,
  LeadType,
  LeadEmailType,
  LeadEmailParamsType,
  DeliveryType,
  ContactDetailsType,
  CompanyInfoType,
} from "bus/lead/models";

// Utils
import { http } from "api/network-provider";
import { HEADER_COUNTRY } from "utils/constants";

export const leadService = Object.freeze({
  createLead: (payload: LeadParamsType) => {
    const { data } = payload;
    return http.post<HttpResponse<LeadType>>("/lead", data);
  },
  fetchLeadProfile: (payload: LeadParamsType) => {
    const { params } = payload;
    const hash = params?.hash;
    return http.get<HttpResponse<LeadProfileType>>(`/lead/${String(hash)}/profile`);
  },
  createCompanyInfo: (payload: LeadParamsType) => {
    const { params, data } = payload;
    const hash = params?.hash;
    return http.post<HttpResponse<CompanyInfoType>>(`/lead/${String(hash)}/company`, data);
  },
  createDelivery: (payload: LeadParamsType) => {
    const { params, data } = payload;
    const hash = params?.hash;
    return http.post<HttpResponse<DeliveryType>>(`/lead/${String(hash)}/delivery`, data);
  },
  createDetails: (payload: LeadParamsType) => {
    const { params, data } = payload;
    const hash = params?.hash;
    return http.post<HttpResponse<ContactDetailsType>>(`/lead/${String(hash)}/details`, data);
  },
  createVerifiedLead: (payload: LeadParamsType) => {
    const { data, meta } = payload;
    const config = {
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
    };
    return http.post<HttpResponse<LeadHash>>("/lead/invited", data, config);
  },
  fetchInvitedLeadEmail: (payload: LeadEmailParamsType) => {
    const { params, meta } = payload;
    const config = {
      params,
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
      paramsSerializer: (p: AxiosRequestConfig): string => QS.stringify(p, { encode: false }),
    };
    return http.get<HttpResponse<LeadEmailType>>("/lead/invited/email", config);
  },
});
