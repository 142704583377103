// Core
import { Fragment, useState, MouseEvent } from "react";

// Components
import { Text, TextProps } from "components/ui/Typography/Text";
import { Button } from "../Button";
import { Icon } from "../Icon";

// Definitions
import type { SizeType } from "antd/es/config-provider/SizeContext";

// Utils
import st from "./Styles.module.less";

export type TextExpandableProps = TextProps & {
  text: string;
  cutOn?: number;
  btnIcon?: boolean;
  btnTextSize?: SizeType;
  expandAnchor?: string;
  collapseAnchor?: string;
  onClick?: () => void;
  collapsed?: boolean;
};
/*
  TODO: split styles for breakpoint, move call with useBreakpoint in common
 */
export const TextExpandable = (props: TextExpandableProps) => {
  const {
    text,
    cutOn,
    btnIcon = false,
    btnTextSize = "small",
    expandAnchor,
    collapseAnchor,
    onClick,
    ...textProps
  } = props;
  const [collapse, setCollapse] = useState<boolean>(props.collapsed ?? true);

  const textLength = text.length;
  const textExpandable = !!cutOn && textLength > cutOn;
  const textCollapse = text.substring(0, cutOn) + "...";
  const icon = btnIcon && <Icon name={`${collapse ? "DownOutlined" : "UpOutlined"}`} />;

  const toggle = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setCollapse((prev) => {
      return !prev;
    });

    onClick?.();
  };

  return (
    <div className={st.content}>
      <Text {...textProps}>
        <Fragment>
          <span>{collapse ? textCollapse : text}</span>
          {textExpandable && (
            <span className={st["content-action-btn"]}>
              <Button
                size={btnTextSize}
                type="link"
                noStyle
                btnType="article-link"
                onClick={toggle}
                icon={icon}
              >
                {collapse ? expandAnchor : collapseAnchor}
              </Button>
            </span>
          )}
        </Fragment>
      </Text>
    </div>
  );
};
