// Components
import { Space as AntSpace } from "antd";

// Definitions
import type { SpaceProps as AntSpaceProps } from "antd";

type SpaceProps = AntSpaceProps;

export const Space = (props: SpaceProps) => {
  return <AntSpace {...props} />;
};
