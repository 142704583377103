// Definitions
import type { StaticDataType, StaticDataInnerShape } from "bus/staticData/models";

// Domains
import { staticDataKeys } from "bus/staticData/store";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { staticDataService } from "bus/staticData/service";
import { errorHandler404 } from "utils/error-handlers";

type UseStaticDataFetchType = {
  loadingStaticData: boolean;
  staticData: StaticDataInnerShape | null;
};

type StaticDataQueryKey = [string, { types: StaticDataType[] }];

export const useStaticDataFetch = (
  values: StaticDataType[],
  options?: string,
): UseStaticDataFetchType => {
  const keyParams = { types: values, ...(options && { options }) };
  const params = { types: values };
  const fetchStaticData = useQueryAsync<
    StaticDataInnerShape,
    StaticDataInnerShape,
    StaticDataQueryKey
  >({
    key: [staticDataKeys.staticData, keyParams],
    name: "fetchStaticDataQuery",
    fetcher: staticDataService.fetchPublic,
    fetcherPayload: { params },
    handlerError404: errorHandler404,
    options: {
      refetchOnMount: "always",
      enabled: !!params?.types.length,
    },
  });

  return {
    loadingStaticData: fetchStaticData.isLoading || false,
    staticData: fetchStaticData.data || null,
  };
};
