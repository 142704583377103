// Core
import React, { PropsWithChildren, ReactElement } from "react";
import { equals } from "ramda";

// Definitions
import type { LeadType } from "bus/lead/models";

// Hooks
import { useLocalStorage } from "hooks/useLocalStorage";
import { useModalContext } from "hooks/useModalContext";

type ContentType = {
  signupRequest: LeadType | null;
  setSignupRequest: (value: LeadType | null) => void;
};

type FormContextProviderPropsType = {
  children: ReactElement;
};

export const AuthUserDraftContext = React.createContext<ContentType>({
  signupRequest: null,
  setSignupRequest: () => {},
});

export const AuthUserDraftProvider = ({
  children,
}: PropsWithChildren<FormContextProviderPropsType>) => {
  const [signupRequest, setSignupRequest] = React.useState<LeadType | null>(null);
  const { isModal } = useModalContext();
  const [signupRequestStorage, setSignupRequestStorage] = useLocalStorage<LeadType | null>(
    "signupRequestForm",
    null,
  );

  React.useEffect(() => {
    return () => {
      setSignupRequestStorage(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (signupRequestStorage && isModal) {
      setSignupRequest(signupRequestStorage);
    }
    return () => {
      setSignupRequestStorage(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModal]);

  React.useEffect(() => {
    if (!equals(signupRequest, signupRequestStorage) && signupRequest !== null && isModal) {
      setSignupRequestStorage(null);
      return;
    }
    if (!equals(signupRequest, signupRequestStorage) && signupRequest !== null) {
      setSignupRequestStorage(signupRequest);
    }
    return () => {
      setSignupRequestStorage(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupRequest]);

  return (
    <AuthUserDraftContext.Provider
      value={{
        signupRequest,
        setSignupRequest,
      }}
    >
      {children}
    </AuthUserDraftContext.Provider>
  );
};
