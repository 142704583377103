// Utils
import { book } from "init/book";

export const introSignin = {
  modals: {
    leadRegistered: {
      title: "signin:modals.leadRegisteredError.title",
      desc: "signin:modals.leadRegisteredError.desc",
    },
    userConsent: {
      title: "signin:modals.userConsent.title",
      desc: "signin:modals.userConsent.desc",
    },
  },
};

export const signupHeader = {
  title: "signup:stepsTitle.title",
  desc: "signup:stepsTitle.desc",
};

export const signupSteps = [
  {
    label: "signup:stepsLabels.companyInformation",
  },
  {
    label: "signup:stepsLabels.delivery",
  },
  {
    label: "signup:stepsLabels.contactDetails",
  },
];

export const consentSignup = "inputs:consentSignup";

export const consentCallBack: Record<string, string> = {
  visible: "inputs:consentCallBack.signupVisible",
  expand: "inputs:consentCallBack.signupExpand",
};

export const legalNoticeSignup: Record<string, string> = {
  text: "common:helpText.signupRequired",
  hrefTermsConditions: book.termConditions,
  hrefBenefitsTermsConditions: book.benefitsTermsConditions,
  hrefPrivacyPolicy: book.privacyPolicy,
};

export const legalNoticeCallBack: Record<string, string> = {
  text: "common:helpText.callBackRequired",
  href: book.privacyPolicy,
};
