// Core
import { useContext } from "react";
import { useTranslation } from "next-i18next";

// Components
import { LeadSignupForm } from "components/common/Forms/LeadSignup";

// Definitions
import { AuthUserDraftContext } from "contexts/auth-user-draft";

// Bus
import { useUserCountry } from "bus/user/hooks/useUserCountry";
import { useRegistrationStaticDataFetch } from "bus/lead/hooks/useRegistrationStaticDataFetch";

// Utils
import { useLeadCreate } from "bus/lead/hooks/useLeadCreate";
import { introLead } from "data/intro";

type LeadSignupRequestPropsType = {
  uniqKey?: string;
  testId?: string;
  type: "signup" | "modal";
};

export const LeadSignupRequest = ({ uniqKey, testId, type }: LeadSignupRequestPropsType) => {
  const { t } = useTranslation(["intro"]);

  const { userCountry, onUpdateUserCountryByPhone } = useUserCountry();
  const { loadingLeadCreate, onCreateLead } = useLeadCreate();
  const { signupRequest, setSignupRequest } = useContext(AuthUserDraftContext);
  const { loadingStaticData, phoneCodes, countries } = useRegistrationStaticDataFetch({
    country: userCountry,
  });

  if (type === "signup") {
    return (
      <LeadSignupForm
        testId={testId}
        initialValues={signupRequest}
        title={t("intro:registerTitle")}
        uniqKey={uniqKey}
        loadingCreate={loadingLeadCreate}
        loadingStaticData={loadingStaticData}
        country={userCountry}
        countries={countries}
        phoneCodes={phoneCodes}
        onSubmit={onCreateLead}
        onSaveLocalStorage={setSignupRequest}
        onUpdateUserCountry={onUpdateUserCountryByPhone}
      />
    );
  }

  if (type === "modal") {
    return (
      <LeadSignupForm
        testId={testId}
        initialValues={signupRequest}
        title={t(introLead.modalTitle)}
        uniqKey={uniqKey}
        loadingCreate={loadingLeadCreate}
        loadingStaticData={loadingStaticData}
        country={userCountry}
        countries={countries}
        phoneCodes={phoneCodes}
        onSubmit={onCreateLead}
        onSaveLocalStorage={setSignupRequest}
        onUpdateUserCountry={onUpdateUserCountryByPhone}
      />
    );
  }

  return null;
};
