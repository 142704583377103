// Core
import { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, useTranslation } from "next-i18next";
import useDeepCompareEffect from "use-deep-compare-effect";
import { routerPush } from "utils/router";
import cx from "classnames";

// Components
import { Form } from "antd";
import { Button } from "components/ui/Button";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { InputForm } from "components/ui/FormFields/InputForm";
import { FormTitle } from "components/ui/FormTitle";
import { FormHelpText } from "components/ui/FormHelpText";
import { Popover } from "components/ui/Popover";
import { TextExpandable } from "components/ui/TextExpandable";
import { InputCheckbox } from "components/ui/FormFields/InputCheckbox";
import { Link } from "components/common/Link";
import { WrappedFormInput } from "components/common/WrappedFormInput";
import { ConfirmChangeCountry } from "components/common/PopoverViews/ConfirmChangeCountry";
import { WrappedFormSelect } from "components/common/WrappedFormSelect";
import { InputGroup } from "components/ui/FormFields/InputGroup";

// Definitions
import type { LeadType } from "bus/lead/models";
import type { FormSubmitFn } from "models/Forms";
import type { StaticDataItemType } from "bus/staticData/models";
import { LeadSignupEnum } from "./config";
import { UserCountryCodesEnum } from "bus/user/models";

// Hooks
import { useToggle } from "hooks/useToggle";

// Utils
import { book } from "init/book";
import { mapCountriesToIcons, getCountryItemByLocale } from "utils/maps";
import { getFieldsMetaValidation } from "utils/forms";
import { getExcludeValues } from "utils/get-exclude-icon";
import { inputConfig, leadSignupForm } from "./config";
import { consentSignup, legalNoticeSignup } from "data/signin-signup";
import st from "./Styles.module.less";

type LeadSignupFormType = {
  loadingCreate?: boolean;
  loadingStaticData?: boolean;
  initialValues?: LeadType | null;
  uniqKey?: string;
  size?: "middle" | "large";
  title?: string;
  country?: string;
  countries: StaticDataItemType[];
  phoneCodes: StaticDataItemType[];
  onSubmit?: FormSubmitFn<LeadType>;
  onSaveLocalStorage?: (value: LeadType | null) => void;
  onUpdateUserCountry: (value: { country: UserCountryCodesEnum }) => void;
  testId?: string;
};

export const LeadSignupForm = (props: LeadSignupFormType) => {
  const {
    initialValues = null,
    title,
    testId,
    uniqKey = "",
    size = "large",
    loadingCreate,
    loadingStaticData,
    country,
    countries,
    phoneCodes,
    onSubmit,
    onSaveLocalStorage,
    onUpdateUserCountry,
  } = props;
  const { t } = useTranslation();

  const { isToggle, onToggle } = useToggle(true);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const countryPrefix = initialValues?.phonePrefix?.value || country;
  const defaultPhonePrefixOption = countryPrefix
    ? getCountryItemByLocale(phoneCodes, countryPrefix)
    : null;
  const phonePrefixWithIcons = useMemo(() => mapCountriesToIcons(phoneCodes), [phoneCodes]);

  const formProps = useForm<LeadType>({
    defaultValues: { ...leadSignupForm.shape, ...initialValues },
    resolver: zodResolver(leadSignupForm.schema(t)),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState, setError, reset, setValue } = formProps;
  const { isSubmitting } = formState;
  const fieldsMeta = getFieldsMetaValidation(LeadSignupEnum, formState);
  const currentValues = useWatch({ control });
  const selectedCountry = currentValues.phonePrefix?.value as UserCountryCodesEnum;
  const countryLabel = countries.find((el) => el?.value === selectedCountry)?.label;

  const handleExpandUserConsent = () => onToggle(!isToggle);

  const handleSubmitForm = handleSubmit((values): void => {
    onSubmit?.({
      values: getExcludeValues(values),
      acts: {
        setError,
        reset,
      },
    });
  });

  const onSubmitForm = () => {
    if (selectedCountry && selectedCountry !== country) {
      setVisibleConfirm(!visibleConfirm);
      return;
    }
    void (async () => {
      await handleSubmitForm();
    })();
  };

  const handleSignin = () => {
    void (async () => {
      await routerPush(book.signin);
    })();
  };

  const handleConfirmCancel = () => {
    setVisibleConfirm(!visibleConfirm);
    defaultPhonePrefixOption?.icon && setValue("phonePrefix", defaultPhonePrefixOption);
  };

  const handleConfirm = () => {
    setVisibleConfirm(!visibleConfirm);
    if (selectedCountry && selectedCountry !== country) {
      onUpdateUserCountry({
        country: selectedCountry,
      });
    }
    void (async () => {
      await handleSubmitForm();
    })();
  };

  const isTouchedFields = Object.keys(formState.touchedFields).length !== 0;
  useDeepCompareEffect(() => {
    if (currentValues && isTouchedFields) {
      onSaveLocalStorage?.(currentValues as LeadType);
      reset(undefined, { keepErrors: true, keepDirty: false, keepTouched: false });
    }
  }, [currentValues, isTouchedFields, onSaveLocalStorage, reset]);

  useEffect(() => {
    if (initialValues && currentValues && Object.keys(formState.touchedFields).length === 0) {
      Object.keys(initialValues).forEach((key: string) => {
        if (
          initialValues[key as keyof typeof LeadSignupEnum] !==
          currentValues[key as keyof typeof LeadSignupEnum]
        ) {
          setValue(
            LeadSignupEnum[key as keyof typeof LeadSignupEnum],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            (initialValues as never)[key],
          );
        }
      });
    }
  }, [initialValues]);

  useEffect(() => {
    defaultPhonePrefixOption?.icon && setValue("phonePrefix", defaultPhonePrefixOption);
  }, [phoneCodes, countryPrefix, setValue]);

  const testIdRoot = testId ? `${testId}-signup-form` : "signup-form";
  const testIdBtnConfirm = testId ? `${testId}-signup-form-btn-confirm` : "signup-form-btn-confirm";
  const testIdBtnReject = testId ? `${testId}-signup-form-btn-reject` : "signup-form-btn-reject";
  const formStyles = cx(st["lead-signup-form"], {
    [st[`lead-signup-form-size-${size}`]]: Boolean(size),
  });

  return (
    <Form onFinish={onSubmitForm} data-test-id={testIdRoot} className={formStyles}>
      {title && <FormTitle>{title}</FormTitle>}
      <FormBlock>
        <InputForm
          name={LeadSignupEnum.fullName}
          validateStatus={fieldsMeta.fullName.status}
          {...fieldsMeta.fullName.helpText}
        >
          <WrappedFormInput
            id={`${LeadSignupEnum.fullName}${uniqKey}`}
            name={LeadSignupEnum.fullName}
            control={control}
            placeholder={t(inputConfig.fullName.placeholder)}
            hasDisabled={isSubmitting}
            maxLength={inputConfig.fullName.max}
          />
        </InputForm>
      </FormBlock>
      <InputForm
        htmlFor={LeadSignupEnum.phoneNumber}
        type="no-gutter"
        skeletonType="phone-number"
        loading={loadingStaticData}
      >
        <Popover
          open={visibleConfirm}
          trigger={""}
          placement="topLeft"
          content={
            <ConfirmChangeCountry
              onCancel={handleConfirmCancel}
              onConfirm={handleConfirm}
              country={countryLabel}
            />
          }
        >
          <InputGroup type="phone">
            <InputForm
              name={LeadSignupEnum.phonePrefix}
              validateStatus={fieldsMeta.phonePrefix.status}
              {...fieldsMeta.phonePrefix.helpText}
            >
              <WrappedFormSelect
                name={LeadSignupEnum.phonePrefix}
                control={control}
                placeholder={t(inputConfig.phonePrefix.placeholder)}
                options={phonePrefixWithIcons}
                validateStatus={fieldsMeta.phonePrefix.status}
                hasPrefix
              />
            </InputForm>
            <InputForm
              name={LeadSignupEnum.phoneNumber}
              validateStatus={fieldsMeta.phoneNumber.status}
              {...fieldsMeta.phoneNumber.helpText}
            >
              <WrappedFormInput
                id={LeadSignupEnum.phoneNumber}
                name={LeadSignupEnum.phoneNumber}
                control={control}
                placeholder={t(inputConfig.phoneNumber.placeholder)}
                hasDisabled={isSubmitting}
                hasPhoneMask
                unmaskedValue
              />
            </InputForm>
          </InputGroup>
        </Popover>
      </InputForm>
      <FormBlock>
        <InputForm
          name={LeadSignupEnum.email}
          validateStatus={fieldsMeta.email.status}
          {...fieldsMeta.email.helpText}
        >
          <WrappedFormInput
            id={`${LeadSignupEnum.email}${uniqKey}`}
            name={LeadSignupEnum.email}
            control={control}
            placeholder={t(inputConfig.email.placeholder)}
            hasDisabled={isSubmitting}
            maxLength={inputConfig.email.max}
          />
        </InputForm>
      </FormBlock>
      <FormBlock>
        <div className={st["consent-checkbox"]}>
          <InputCheckbox
            size="middle"
            align="top"
            value={isToggle}
            onChange={handleExpandUserConsent}
          >
            <TextExpandable
              text={t(consentSignup)}
              cutOn={90}
              expandAnchor={t("common:collapse.more")}
              collapseAnchor={t("common:collapse.hide")}
              size="12"
            />
          </InputCheckbox>
        </div>
      </FormBlock>
      <FormBlock>
        <div className={st.actions}>
          <Button
            testId={testIdBtnConfirm}
            type="primary"
            disabled={!isToggle}
            htmlType="submit"
            size="large"
            block
            loading={loadingCreate}
          >
            {t("buttons:registration")}
          </Button>
          <Button
            href={book.signin}
            testId={testIdBtnReject}
            type="link"
            size="large"
            block
            mode="custom"
            onClick={handleSignin}
          >
            {t("buttons:signIn")}
          </Button>
        </div>
      </FormBlock>
      <FormHelpText type="second">
        <Trans
          i18nKey={legalNoticeSignup.text}
          components={[
            <Link
              size="12"
              key={legalNoticeSignup.hrefTermsConditions}
              href={legalNoticeSignup.hrefTermsConditions}
            />,
            <Link
              size="12"
              key={legalNoticeSignup.hrefBenefitsTermsConditions}
              href={legalNoticeSignup.hrefBenefitsTermsConditions}
            />,
            <Link
              size="12"
              key={legalNoticeSignup.hrefPrivacyPolicy}
              href={legalNoticeSignup.hrefPrivacyPolicy}
            />,
          ]}
        />
      </FormHelpText>
    </Form>
  );
};
