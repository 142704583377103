// Definitions
import type { LeadType, LeadProfileType } from "bus/lead/models";

export const leadKeys = {
  lead: "lead",
  profile: "lead/profile",
  verifiedLead: "lead/invited",
  invitedEmail: "lead/invited/email",
};

export type LeadStateType = {
  lead: LeadType | null;
  profile: LeadProfileType;
};

export const initialLeadState: LeadStateType = {
  lead: null,
  profile: {
    company: {
      complete: false,
      data: {
        carServiceName: "",
        businessType: null,
        phonePrefix: null,
        phoneNumber: "",
        companyEmail: "",
        registrationNumber: "",
        vat: "",
        quantityEmployees: null,
        quantityAutolifts: null,
      },
    },
    delivery: {
      complete: false,
      data: {
        shippingAddress: {
          companyName: "",
          country: null,
          index: "",
          city: "",
          street: "",
          houseNumber: "",
          additionalDetails: "",
        },
        billingAddress: null,
      },
    },
    details: {
      complete: false,
      data: {
        personalInfo: {
          name: "",
          surname: "",
          position: "",
          email: "",
          number: [],
        },
        representatives: [],
        confirm: false,
        newsletters: false,
        calls: false,
      },
    },
  },
};
