// Definitions
import type { UseRegistrationStaticDataFetchType } from "bus/lead/models";
import { UserCountryCodesEnum } from "bus/user/models";
import { STATIC_DATA_KEYS } from "bus/staticData/models";

// Domains
import { useStaticDataFetch } from "bus/staticData/hooks/useStaticDataFetch";

type UseRegistrationStaticDataFetchProps = {
  country?: UserCountryCodesEnum;
};

export const useRegistrationStaticDataFetch = (
  props?: UseRegistrationStaticDataFetchProps,
): UseRegistrationStaticDataFetchType => {
  const staticDataKeys = [STATIC_DATA_KEYS.phoneCodes, STATIC_DATA_KEYS.countries];
  const { loadingStaticData, staticData: data } = useStaticDataFetch(
    staticDataKeys,
    props?.country,
  );
  const staticData = {
    phoneCodes: data?.phoneCodes || [],
    countries: data?.countries || [],
  };

  return {
    loadingStaticData,
    ...staticData,
  };
};
