export function getExcludeValues<T>(values: T): T {
  const data: Record<string, unknown> = {};
  // eslint-disable-next-line no-loops/no-loops
  for (const item in values) {
    const value = values[item];
    if (Array.isArray(value)) {
      data[item] = value.map((arrayItem) => {
        if (typeof arrayItem === "object") {
          return getExcludeValues(arrayItem) as T;
        } else {
          return arrayItem as T;
        }
      });
    } else if (typeof value === "object") {
      data[item] = getExcludeValues(value);
    } else {
      if (item !== "icon") {
        data[item] = value;
      }
    }
  }
  return data as T;
}
